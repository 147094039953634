import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { PageProps, graphql } from "gatsby";

import PollenTemplate from "../../layouts/pollen";
import PollenFilteredPostsContainer from "../../containers/PollenFilteredPostsContainer/PollenFilteredPostsContainer";
import { PollenPost } from "../../assets/Types";
import { getRandomInt } from "../../utils/getRandomInt";
import { ProductAdQuery } from "../../assets/StaticQueries/ProductAd.query";

type DataProps = {
  allWpPost: {
    nodes: PollenPost[];
  };
};

const PostSearchTemplate: React.FC<PageProps<DataProps>> = ({
  data,
  location,
}) => {
  const [searchString] = useQueryParam("term", StringParam);

  const {
    allWpPost: { nodes: allPosts },
  } = data;
  const { allWpProductAd } = ProductAdQuery();
  const productAdIndex = getRandomInt(allWpProductAd.nodes.length);
  const productAd = allWpProductAd.nodes[productAdIndex];

  const searchStringLowerCase = searchString?.toLowerCase();
  const filteredPosts = searchStringLowerCase
    ? allPosts.filter(
        post =>
          post.content.toLowerCase().includes(searchStringLowerCase) ||
          post.title.toLowerCase().includes(searchStringLowerCase) ||
          post.author.node.name.toLowerCase().includes(searchStringLowerCase)
      )
    : allPosts;

  return (
    <PollenTemplate isNotMainPage pathname={location.pathname}>
      <PollenFilteredPostsContainer
        posts={filteredPosts}
        productAd={productAd}
        searchBy={searchString}
      />
    </PollenTemplate>
  );
};

export default PostSearchTemplate;

export const SEARCH_PAGE_QUERY = graphql`
  query SearchProductAdQuery {
    allWpPost(sort: { fields: date, order: DESC }, limit: 1000) {
      nodes {
        id
        title
        excerpt
        content
        author {
          node {
            avatar {
              url
            }
            name
            uri
          }
        }
        uri
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        articleCtaPo {
          productAdCtaLink
          productAd {
            ... on WpProductAd {
              id
              productAdsC {
                bannerAdsAssociatedMicrocopy
                bannerAdsBackgroundColor
                bannerAdsCtaText
                bannerAdsHeadline
                bannerAdsCtaBackgroundColor
                bannerAdsCtaArrowCheckbox
                bannerAdsImage {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [WEBP, JPG, AUTO]
                      )
                    }
                    publicURL
                  }
                }
                bannerAdsCheckboxList {
                  checkbox
                  copy
                }
              }
            }
          }
        }
      }
    }
  }
`;
