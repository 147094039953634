import React, { useState } from "react";
import { navigate } from "gatsby";
import BlogItem from "../../components/BlogItem/BlogItem";
import SearchInput from "../../components/SearchInput/SearchInput";
import BlogPM from "../PollenSectionContainer/BlogCards/BlogPM/BlogPM";
import { PollenPost, ProductAdNode } from "../../assets/Types";

import styles from "./PollenFilteredPostsContainer.module.scss";

type IPollenFilteredPostsContainer = {
  posts: PollenPost[];
  productAd: ProductAdNode;
  searchBy: string;
  className?: string;
};

const PollenFilteredPostsContainer: React.FC<IPollenFilteredPostsContainer> = ({
  posts,
  productAd,
  searchBy,
  className = "",
}) => {
  const [search, setSearch] = useState(searchBy);
  const hasPosts = posts.length > 0;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/search?term=${search}`);
  };

  return (
    <div className={`full-width ${className}`}>
      <header className={styles.postHeader}>
        <h1 className={styles.garamond}>
          {hasPosts ? posts.length : "No "} articles found for
          <span>{` '${searchBy}'`}</span>
        </h1>
      </header>

      <div className={styles.blogCatGrid}>
        <div className={styles.bclist}>
          {hasPosts ? (
            <>
              {posts?.map((post, index) => (
                <BlogItem key={index} post={post} />
              ))}
            </>
          ) : (
            <SearchInput
              value={search}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
          )}
        </div>

        <div className={styles.bcside}>
          {hasPosts && (
            <SearchInput
              value={search}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
          )}
          <BlogPM productAd={productAd} className={styles.blogProductAds} />
        </div>
      </div>
    </div>
  );
};

export default PollenFilteredPostsContainer;
